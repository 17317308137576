import { Box, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import page1 from "../Assets/Images/page1.png";
import classes from "../Assets/Styles/Home.module.css";
import { useNavigate } from "react-router-dom";
import { Store } from "../Store";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GET_ACESS_TOKEN } from "../MProjectConst";
import { GET_GENERATE_TOKEN } from "../MProjectConst";
import Loader from "../Components/Loader";

const Home = () => {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [text, setText] = useState();
  const [serverLoader, setServerLoader] = useState(true);

  useEffect(() => {
    const generateToken = async () => {
      const secretKey =
        "34753778214125432A462D4A614E645267556B58703273357638792F423F4528";
      const url =
        GET_GENERATE_TOKEN +
        `/subscription?data=${encodeURIComponent(
          JSON.stringify({ secret_key: secretKey })
        )}`;

      await axios
        .get(url)
        .then(async (response) => {
          if (response.data && response.status === 200) {
            setServerLoader(false);
            ctxDispatch({
              type: "SAVE_USER_TOKEN",
              payload: response.data.body.token,
            });
            localStorage.setItem(
              "userToken",
              JSON.stringify(response.data.body.token)
            );
          } else {
            console.log("error", "Server is down!");
            toast.error("error", "Server is down!");
          }
        })
        .catch((error) => {
          setServerLoader(false);
          console.log("server is down");
          toast.error("server is down");
        });
    };

    const getAccessToken = async () => {
      await axios
        .get(GET_ACESS_TOKEN)
        .then((response) => {
          ctxDispatch({
            type: "SAVE_ACESS_TOKEN",
            payload: response.data.access_token,
          });
          localStorage.setItem(
            "accessToken",
            JSON.stringify(response.data.access_token)
          );
        })
        .catch((err) => {
          console.log(err);
          toast.error("server is down");
        });
    };

    generateToken();
    getAccessToken();
  }, []);

  function handleChangeInText(e) {
    setText(e.target.value);
  }

  function generateSample() {
    // const finalCount = count
    if (text) {
      ctxDispatch({
        type: "SAVE_SAMPLE_CREATED_DATA",
        payload: { text },
      });
      localStorage.setItem("sampleCreatedData", JSON.stringify({ text }));
      navigate("/createdsample");
    } else {
      toast.error("Enter the Prompt!");
    }
  }

  return (
    <Box>
      <ToastContainer position="top-center" theme="dark" color="yellow" />i
      {serverLoader ? <Loader /> : <></>}
      {/* {console.log(userInfo.uid)} */}
      <Grid
        container
        item
        direction="row"
        alignItems="flex-end"
        justifyContent="center"
      >
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          item
          color="white"
          marginTop={25}
          marginLeft={{ xs: 10, sm: 20, md: 20, lg: 0 }}
        >
          <img src={page1} alt="mainPage" className={classes["HomeImage"]} />
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          marginLeft={{ xs: 10, sm: 20, md: 20, lg: 0 }}
          item
          color="white"
          className={classes["HomeCard"]}
        >
          <Grid
            item
            style={{ fontFamily: "Roboto" }}
            fontSize={{ xs: 30, sm: 40, md: 40, lg: 54 }}
            marginBottom={3}
            marginTop={{ xs: 15, sm: 15, md: 15, lg: 0 }}
          >
            Tell us about your show <br /> in a couple of sentences
          </Grid>
          <Grid
            container
            item
            direction="row"
            // justifyContent="center"
            className={classes["HomeCardDetails"]}
            padding={4}
            width="90%"
          >
            <Grid item style={{ fontSize: "24px" }}>
              Text prompt
            </Grid>
            <Grid xs={12} item marginTop={1} marginBottom={10}>
              <textarea
                placeholder="Ex: Mother and daughter doing a car show.
Ex: Three friends who have a podcast. 
Ex: Friends doing a show about the Roman Times"
                className={classes["HomeTextArea"]}
                onChange={handleChangeInText}
              ></textarea>
            </Grid>

            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                xs={12}
                sm={12}
                md={5}
                marginTop={{ xs: 3, sm: 3, md: 0 }}
                marginBottom={{ xs: 3, sm: 3, md: 0 }}
                item
              >
                <button
                  onClick={() => generateSample()}
                  className={classes["HomeButton"]}
                >
                  Generate
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
