import React, { useContext, useEffect, useState } from "react";
import classes from "../Assets/Styles/CreatedSample.module.css";
import { Grid } from "@mui/material";
import Person from "../Assets/Images/Group 1045.png";
import Person2 from "../Assets/Images/charNew.png";
import UnderAgeFemale from "../Assets/Images/My project-4.jpg";
import { Store } from "../Store";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GET_ANOTHER_BIO, GET_CHARACTERS } from "../MProjectConst";
import { GET_IMAGE } from "../MProjectConst";

const initialState = {
  name: "",
  gender: "",
  category: "",
  adjectives: "",
  birth_date: "",
  ageRange: "",
  race: "",
  style: "",
  intelligence: "",
  strength: "",
  funny: "",
  mysteriosity: "",
  backstory: "",
  number: "0",
};

const CreatedSample = () => {
  const [data, setData] = useState(initialState);
  const {
    name,
    gender,
    category,
    adjectives,
    birth_date,
    ageRange,
    race,
    style,
    intelligence,
    strength,
    funny,
    mysteriosity,
    backstory,
  } = data;
  const [focus, setFocus] = useState("");
  const [generateImage, setGenerateImage] = useState(false);
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { sampleCreatedData, userToken } = state;
  const [loading, setLoading] = useState(true);
  const [loadingImages, setLoadingImages] = useState(true);
  const [stories, setStories] = useState([]);
  const [CharImages, setCharImages] = useState([]);
  const [greenScript, setGreenScript] = useState(false);

  const navigate = useNavigate();

  const ChangeFocus = (index) => {
    setFocus(stories[index].name);
    const updatedData = stories[index];
    updatedData.number = index;
    updatedData.selected = false;
    setData(updatedData);
    setGenerateImage(false);
    setCharImages([]);

    const updatedStories = [...stories];
    updatedStories[data.number].stopImageGenerating = false;
    setStories(updatedStories);
  };

  const handleChangeInAllForms = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  function handleName(e) {
    const updatedStories = [...stories];
    // // setData({ ...data, [e.target.name]: e.target.value });
    updatedStories[data.number].name = e.target.value;
    setStories(updatedStories);
  }

  function handleGender(e) {
    const updatedStories = [...stories];
    updatedStories[data.number].gender = e.target.value;
    setStories(updatedStories);
  }

  function handleCategory(e) {
    const updatedStories = [...stories];
    updatedStories[data.number].category = e.target.value;
    setStories(updatedStories);
  }

  function handleAjectives(e) {
    const updatedStories = [...stories];
    updatedStories[data.number].adjectives = e.target.value;
    setStories(updatedStories);
  }

  function handleDOB(e) {
    const updatedStories = [...stories];
    updatedStories[data.number].birth_date = e.target.value;
    setStories(updatedStories);
  }

  function handleAge(e) {
    const updatedStories = [...stories];
    updatedStories[data.number].ageRange = e.target.value;

    setStories(updatedStories);
  }

  function handleRace(e) {
    const updatedStories = [...stories];
    updatedStories[data.number].race = e.target.value;
    setStories(updatedStories);
  }

  function handleStyle(e) {
    const updatedStories = [...stories];
    if (generateImage) {
      updatedStories[data.number].style = e.target.value;
      // setStories(updatedStories);
      // const updatedStories = stories.map((prev) => {
      //   return {
      //     ...prev,
      //     style: e.target.value,
      //   };
      // });
      updatedStories[data.number].stopImageGenerating = false;

      setStories(updatedStories);
      setCharImages([]);
      generateImageHandler();
    } else {
      updatedStories[data.number].style = e.target.value;
      setStories(updatedStories);
    }
  }

  function handleIntelligence(num) {
    const updatedStories = [...stories];
    updatedStories[data.number].intelligence = num;
    setStories(updatedStories);
  }

  function handleStrength(num) {
    const updatedStories = [...stories];
    updatedStories[data.number].strength = num;
    setStories(updatedStories);
  }

  function handleFunny(num) {
    const updatedStories = [...stories];
    updatedStories[data.number].funny = num;
    setStories(updatedStories);
  }

  function handleMysteriosity(num) {
    const updatedStories = [...stories];
    updatedStories[data.number].mysteriosity = num;
    setStories(updatedStories);
  }

  function handleTextArea(e) {
    const updatedStories = [...stories];
    updatedStories[data.number].backstory = e.target.value;
    setStories(updatedStories);
  }

  function CharacterImageHandler(image, index) {
    const newdata = CharImages.map((object) => {
      // Set the attribute to false in each object
      return { ...object, selected: false };
    });
    setCharImages(newdata);

    const updateSelectedImage = [...newdata];
    updateSelectedImage[index].selected = !updateSelectedImage[index].selected;
    setCharImages(updateSelectedImage);

    const updatedStories = [...stories];
    updatedStories[data.number].image = image;
    setStories(updatedStories);

    const allImagesTrue = stories.every((obj) => obj.image);
    if (allImagesTrue) {
      setGreenScript(true);
    }
  }

  function getImageSr(name, index) {
    if (stories[index].image) {
      return stories[index].image;
    } else if (name === focus) {
      return Person2;
    } else {
      return Person;
    }
  }

  const generateScriptHandler = () => {
    const allImagesTrue = stories.every((obj) => obj.image);

    if (allImagesTrue) {
      ctxDispatch({ type: "SAVE_CHARACTERS", payload: stories });
      localStorage.setItem("characters", JSON.stringify(stories));
      navigate("/script");
    } else {
      toast.error("Please Find Look For All Characters");
    }
  };

  const generateAnotherBio = () => {
    const fetchData = async () => {
      // const newData = stories[data.number];

      axios
        .post(GET_ANOTHER_BIO, {
          name: data.name,
          gender: data.gender,
          category: data.category,
          birth_date: data.birth_date,
          adjectives: data.adjectives,
          age: data.age,
          race: data.race,
          style: data.style,
        })
        .then(async (response) => {
          const updatedStories = [...stories];
          updatedStories[data.number].backstory =
            response.data.updatedBackstory;
          setStories(updatedStories);
        })
        .catch((error) => {
          return error;
        })
        .finally(() => {
          toast.success(" New Bio has been added");
        });
    };

    fetchData();
  };

  const generateImageHandler = async () => {
    setGenerateImage(true);
    setLoadingImages(true);

    const updatedStories = [...stories];
    updatedStories[data.number].stopImageGenerating = true;
    setStories(updatedStories);

    // console.log(data);

    if (!userToken) {
      alert("No token found");
      toast.error("No token found");
      return;
    }
    let numberOfImagesGenerated = 50;

    data.images = [];
    data.seeds = [];

    for (let i = 0; i < numberOfImagesGenerated; i++) {
      const selectedModel = data.style;
      const selectedGender = data.gender;
      const selectedRace = data.race;
      const selectedAge = data.ageRange;

      const imageData = {
        token: userToken,
        seed: Math.floor(Math.random() * 10000).toString(),
        model: selectedModel,
        gender: selectedGender,
        race: selectedRace,
        age: selectedAge,
      };

      if (i === 3 && selectedAge === "3-9" && selectedGender === "Female") {
        // this.characters[selectedCharacter].images[3] =
        //   "../../assets/My project-1.jpg";
        setCharImages((prev) => [...prev, UnderAgeFemale]);
      } else {
        try {
          const queryString = encodeURIComponent(JSON.stringify(imageData));
          const url = GET_IMAGE + `/generate_image?data=${queryString}`;
          const response = await axios.get(url);

          if (
            response.data &&
            response.status === 200 &&
            response.data.status_code === 200
          ) {
            const generatedImage =
              GET_IMAGE + response.data.body.generated_image;
            data.images.push(generatedImage);

            data.images = data.images.filter((image) => {
              if (
                selectedModel === data.style &&
                selectedGender === data.gender &&
                selectedRace === data.race &&
                selectedAge === data.ageRange
              ) {
                data.seeds.push(imageData.seed);

                if (stories[data.number].stopImageGenerating) {
                  const imageData = {
                    selected: false,
                    image: image,
                  };

                  setCharImages((prev) => [...prev, imageData]);
                  // setCharImages(() => [{ ...CharImages, image: image }]);
                }

                if (i === numberOfImagesGenerated - 1) {
                  setLoadingImages(false);
                }
              }
            });
          } else {
            console.log(response.data.body.message);
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log("Request canceled");
          } else {
            setLoading(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let prompt = sampleCreatedData.text;

      setLoading(true);
      axios
        .post(GET_CHARACTERS, {
          task: prompt,
        })
        .then(async (response) => {
          setStories(response.data.results.characters);
        })
        .catch((error) => {
          return error;
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!loading) {
      setFocus(stories[data.number].name);

      const updatedData = stories[data.number];
      updatedData.number = data.number;
      setData(updatedData);
    }
  }, [stories, data]);

  return (
    <Grid item container direction="row" justifyContent="center" paddingTop={5}>
      <ToastContainer position="top-center" theme="dark" color="yellow" />
      {/* {console.log(stories[data.number])} */}
      {/* {console.log(stories)} */}
      {/* {console.log(data)} */}
      {/* {console.log(CharImages)} */}

      {loading ? (
        <ClipLoader color={"#F0FD00"} loading={loading} size={100} />
      ) : (
        <Grid
          item
          container
          justifyItems="flex-end"
          alignItems="center"
          justifyContent="center"
        >
          {stories.map((character, index) => (
            <Grid
              item
              container
              xs={12}
              sm={1.4}
              md={1.4}
              lg={1.2}
              xl={0.8}
              direction="column"
              alignItems="center"
              alignContent="center"
              marginRight={5}
              key={index}
            >
              <Grid item>
                <img
                  src={getImageSr(character.name, index)}
                  // src={character.name === focus ? data.image : data.image}
                  onClick={() => ChangeFocus(index)}
                  className={
                    character.name === focus
                      ? classes["SmapleProfileActive"]
                      : classes["SmapleProfile"]
                  }
                  alt="Person"
                />
              </Grid>
              <Grid
                item
                className={
                  character.name === focus
                    ? classes["SmapleAvatarNameActive"]
                    : classes["SmapleAvatarName"]
                }
              >
                {character.name}
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}

      <Grid
        item
        container
        width="100%"
        marginLeft={15}
        marginRight={15}
        marginTop={7}
        className={classes["SampleDetails"]}
      >
        <Grid
          item
          container
          display="row"
          justifyContent="space-between"
          // spacing={4}
          justifyItems="center"
          paddingTop={5}
          xs={12}
          marginBottom={2}
          minWidth="400px"
        >
          <Grid
            // xs={3}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={3}
            container
            item
            color="white"
            marginLeft={5}
            // marginLeft={{ lg: 5, md: 60, xs: 5 }}
            direction="column"
            className={classes["SampleFonts"]}
            gap={1.5}
            marginTop={2}
          >
            <Grid item>
              Name:
              <input
                value={name}
                onChange={(e) => handleName(e)}
                name="name"
                className={classes["SampleInputForm"]}
              ></input>
            </Grid>
            <Grid item>
              Gender:
              <select
                name="gender"
                value={gender}
                onChange={(e) => handleGender(e)}
                className={classes["SampleSelectForm"]}
              >
                <option>Male</option>
                <option>Female</option>
              </select>
            </Grid>
            <Grid item>
              Category:
              <input
                value={category}
                onChange={(e) => handleCategory(e)}
                name="Category"
                className={classes["SampleInputForm"]}
              ></input>
            </Grid>
            <Grid item>
              Adjectives:
              <input
                value={adjectives}
                onChange={(e) => handleAjectives(e)}
                name="Adjectives"
                className={classes["SampleInputForm"]}
              ></input>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={3}
            container
            item
            color="white"
            // marginLeft={24}
            marginLeft={{ lg: 0, md: 5, xs: 5 }}
            marginTop={{ md: 2, sm: 2, xs: 2 }}
            direction="column"
            className={classes["SampleFonts"]}
            gap={1.5}
          >
            <Grid item>
              Birth Date:
              <input
                value={birth_date}
                onChange={(e) => handleDOB(e)}
                name="birth_date"
                className={classes["SampleInputForm"]}
              ></input>
            </Grid>
            <Grid item>
              Age:
              <select
                name="age"
                value={ageRange}
                onChange={(e) => handleAge(e)}
                className={classes["SampleSelectForm"]}
                // disabled={generateImage}
              >
                <option>Random</option>
                <option>0-2</option>
                <option>3-9</option>
                <option>10-19</option>
                <option>20-29</option>
                <option>30-39</option>
                <option>40-49</option>
                <option>50-59</option>
                <option>60-69</option>
                <option value="60-68">70+</option>
              </select>
            </Grid>
            <Grid item>
              Race:
              <select
                name="race"
                value={race}
                onChange={(e) => handleRace(e)}
                className={classes["SampleSelectForm"]}
                // disabled={generateImage}
              >
                <option>Random</option>
                <option>White</option>
                <option>Black</option>
                <option>Indian</option>
                <option>Asian</option>
              </select>
            </Grid>
            <Grid item>
              Style:
              <select
                name="style"
                value={style}
                onChange={(e) => handleStyle(e)}
                className={classes["SampleSelectForm"]}
                // disabled={generateImage}
              >
                <option>Random</option>
                <option value="Human">Photorealistic</option>
                <option value="cartoon_style_1">Photo Polish</option>
                <option value="cartoon_style_2">Airbrushed</option>
                <option value="cartoon_style_3">Fine art</option>
                <option value="cartoon_style_4">Cartoon stylized</option>
                <option value="cartoon_style_5">3-D Animated</option>
                <option value="cartoon_style_6">2-D Art</option>
                <option value="cartoon_style_7">2-D Polish</option>
              </select>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={3}
            container
            item
            color="white"
            marginLeft={{ lg: 0, md: 5, xs: 5 }}
            marginTop={{ md: 2, sm: 2, xs: 2 }}
            direction="column"
            className={classes["SampleFontsExtra"]}
            gap={1.5}
          >
            <Grid item container direction="row" gap={1.5}>
              Intelligence:
              <Grid item marginLeft={1}>
                <div
                  className={
                    intelligence >= 1
                      ? classes["circleActive"]
                      : classes["circle"]
                  }
                  onClick={() => handleIntelligence(1)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    intelligence >= 2
                      ? classes["circleActive"]
                      : classes["circle"]
                  }
                  onClick={() => handleIntelligence(2)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    intelligence >= 3
                      ? classes["circleActive"]
                      : classes["circle"]
                  }
                  onClick={() => handleIntelligence(3)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    intelligence >= 4
                      ? classes["circleActive"]
                      : classes["circle"]
                  }
                  onClick={() => handleIntelligence(4)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    intelligence >= 5
                      ? classes["circleActive"]
                      : classes["circle"]
                  }
                  onClick={() => handleIntelligence(5)}
                ></div>
              </Grid>
            </Grid>
            <Grid item container direction="row" gap={1.5}>
              Strength:
              <Grid item marginLeft={4.2}>
                <div
                  className={
                    strength >= 1 ? classes["circleActive"] : classes["circle"]
                  }
                  onClick={() => handleStrength(1)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    strength >= 2 ? classes["circleActive"] : classes["circle"]
                  }
                  onClick={() => handleStrength(2)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    strength >= 3 ? classes["circleActive"] : classes["circle"]
                  }
                  onClick={() => handleStrength(3)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    strength >= 4 ? classes["circleActive"] : classes["circle"]
                  }
                  onClick={() => handleStrength(4)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    strength >= 5 ? classes["circleActive"] : classes["circle"]
                  }
                  onClick={() => handleStrength(5)}
                ></div>
              </Grid>
            </Grid>
            <Grid item container direction="row" gap={1.5}>
              Funny:
              <Grid item marginLeft={6.7}>
                <div
                  className={
                    funny >= 1 ? classes["circleActive"] : classes["circle"]
                  }
                  onClick={() => handleFunny(1)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    funny >= 2 ? classes["circleActive"] : classes["circle"]
                  }
                  onClick={() => handleFunny(2)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    funny >= 3 ? classes["circleActive"] : classes["circle"]
                  }
                  onClick={() => handleFunny(3)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    funny >= 4 ? classes["circleActive"] : classes["circle"]
                  }
                  onClick={() => handleFunny(4)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    funny >= 5 ? classes["circleActive"] : classes["circle"]
                  }
                  onClick={() => handleFunny(5)}
                ></div>
              </Grid>
            </Grid>
            <Grid item container direction="row" gap={1.5}>
              Mysteriosity:
              <Grid item marginLeft={0.6}>
                <div
                  className={
                    mysteriosity >= 1
                      ? classes["circleActive"]
                      : classes["circle"]
                  }
                  onClick={() => handleMysteriosity(1)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    mysteriosity >= 2
                      ? classes["circleActive"]
                      : classes["circle"]
                  }
                  onClick={() => handleMysteriosity(2)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    mysteriosity >= 3
                      ? classes["circleActive"]
                      : classes["circle"]
                  }
                  onClick={() => handleMysteriosity(3)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    mysteriosity >= 4
                      ? classes["circleActive"]
                      : classes["circle"]
                  }
                  onClick={() => handleMysteriosity(4)}
                ></div>
              </Grid>
              <Grid item>
                <div
                  className={
                    mysteriosity >= 5
                      ? classes["circleActive"]
                      : classes["circle"]
                  }
                  onClick={() => handleMysteriosity(5)}
                ></div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          marginLeft={5}
          marginTop={3}
          marginRight={4}
          marginBottom={3.5}
          width="100%"
        >
          <textarea
            width="100%"
            className={classes["SmapleTextArea"]}
            name="backstory"
            value={backstory}
            onChange={(e) => handleTextArea(e)}
          ></textarea>
        </Grid>
      </Grid>
      <Grid item container direction="row" paddingTop={5}>
        <Grid
          item
          marginLeft={{ xs: 15, sm: 30, md: 5, lg: 10, xl: 24 }}
          sm={12}
          md={4}
          marginTop={2}
        >
          <button
            className={classes["SampleButton"]}
            onClick={() => generateAnotherBio()}
          >
            Generate Another Bio
          </button>
        </Grid>
        <Grid
          item
          sm={12}
          md={4}
          marginLeft={{ xs: 15, sm: 30, md: 0 }}
          marginTop={2}
        >
          <button
            onClick={() => {
              generateImageHandler();
            }}
            className={classes["SampleActiveButton"]}
          >
            Find Look
          </button>
        </Grid>
        <Grid
          item
          sm={12}
          md={2}
          marginLeft={{ xs: 15, sm: 30, md: 0 }}
          marginTop={2}
        >
          <button
            onClick={() => generateScriptHandler()}
            className={
              greenScript
                ? classes["SampleActiveButtonGreen"]
                : classes["SampleActiveButton"]
            }
          >
            Start Scripting
          </button>
        </Grid>
      </Grid>
      {generateImage ? (
        <Grid
          item
          container
          marginTop={15}
          fontSize={{ md: 30, lg: 40 }}
          // fontSize="40px"
          fontWeight="900"
          // marginLeft={15}
          marginLeft={{ xs: 5, sm: 15, md: 15 }}
          marginBottom={3}
        >
          <Grid item color="white" xs={12} sm={4} md={4}>
            Choose the image of {name}
          </Grid>
        </Grid>
      ) : (
        <Grid></Grid>
      )}
      {generateImage ? (
        <Grid
          item
          container
          direction="row"
          className={classes["SampleChooseContainer"]}
          marginTop={0}
          justifyContent="center"
          alignContent="center"
          width="2000px"
          marginLeft={{ xs: 5, sm: 14, md: 14 }}
          marginRight={{ xs: 5, sm: 14, md: 14 }}
          gap={15}
        >
          {CharImages.map((image, index) => (
            <Grid
              key={image.image}
              item
              xs={4}
              sm={4}
              md={3}
              lg={1.5}
              container
              direction="column"
              marginTop={5}
            >
              <Grid item>
                <img
                  src={image.image}
                  onClick={() => {
                    CharacterImageHandler(image.image, index);
                    // SetSelectImage(!selectImage);
                  }}
                  className={
                    CharImages[index].selected
                      ? classes["SampleChooseImageSelected"]
                      : classes["SampleChooseImage"]
                  }
                  alt="Person"
                />
              </Grid>
              <Grid item marginTop={1}>
                <button className={classes["SampleEditButton"]}>edit</button>
              </Grid>
            </Grid>
          ))}
          <Grid item marginTop={10}>
            {" "}
            <ClipLoader color={"#F0FD00"} loading={loadingImages} size={100} />
          </Grid>
        </Grid>
      ) : (
        <Grid></Grid>
      )}
    </Grid>
  );
};

export default CreatedSample;
