import React from "react";
import classes from "../Assets/Styles/Loader.module.css";
import { Grid } from "@mui/material";

const Loader = () => {
  return (
    <Grid className={classes["loader-overlay"]}>
      <Grid className={classes["loader"]}></Grid>
    </Grid>
  );
};

export default Loader;
