import { createContext, useReducer } from "react";

export const Store = createContext();

const initialState = {
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null,

  sampleCreatedData: localStorage.getItem("sampleCreatedData")
    ? JSON.parse(localStorage.getItem("sampleCreatedData"))
    : {},

  userToken: localStorage.getItem("userToken")
    ? JSON.parse(localStorage.getItem("userToken"))
    : {},

  accessToken: localStorage.getItem("accessToken")
    ? JSON.parse(localStorage.getItem("accessToken"))
    : {},

  characters: localStorage.getItem("characters")
    ? JSON.parse(localStorage.getItem("characters"))
    : {},
};

function reducer(state, action) {
  switch (action.type) {
    case "USER_SIGNIN":
      return { ...state, userInfo: action.payload };
    case "USER_SIGNOUT":
      return {
        ...state,
        userInfo: null,
        sampleCreatedData: {},
      };
    case "SAVE_SAMPLE_CREATED_DATA":
      return {
        ...state,
        sampleCreatedData: action.payload,
      };

    case "SAVE_USER_TOKEN":
      return {
        ...state,
        userToken: action.payload,
      };

    case "SAVE_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.payload,
      };

    case "SAVE_CHARACTERS":
      return {
        ...state,
        characters: action.payload,
      };

    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
