import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import classes from "../Assets/Styles/Script.module.css";
import {
  GET_ACESS_TOKEN,
  GET_SCRIPT,
  GET_SCRIPT_AUDIO,
} from "../MProjectConst";
import { GET_VIDEO_FOR_ONE } from "../MProjectConst";
import { GET_VIDEO } from "../MProjectConst";
import { GET_RANDOM_TOPIC } from "../MProjectConst";
import { GET_DOWNLOADED_VIDEO } from "../MProjectConst";
import axios from "axios";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PauseIcon from "@mui/icons-material/Pause";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import TuneIcon from "@mui/icons-material/Tune";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import TheatersIcon from "@mui/icons-material/Theaters";
import addChatIcon from "../Assets/Images/add.png";
import deleteChat from "../Assets/Images/delete.png";
import arrowUp from "../Assets/Images/arrow-up.png";
import arrowDwon from "../Assets/Images/arrow-down.png";
import backMenu from "../Assets/Images/slider.png";
import questionIcon from "../Assets/Images/question.png";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import plusIcon from "../Assets/Images/plus.png";
import smileIcon from "../Assets/Images/smile.png";
import dislikeIcon from "../Assets/Images/dislike.png";
import relievedIcon from "../Assets/Images/relieved.png";
import sadIcon from "../Assets/Images/sad.png";
import angryIcon from "../Assets/Images/angry.png";
import { Store } from "../Store";
import { FadeLoader } from "react-spinners";
import { RiseLoader } from "react-spinners";
import { BarLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Components/Loader";
import { json } from "react-router-dom";

const initialState = {
  dialogue: "",
  character: "",
  image: questionIcon,
  addChat: false,
  menu: false,
};

const Script = () => {
  const [generateScript, setGenerateScript] = useState(false);
  const [editChat, setEditChat] = useState(false);
  const [showChar, setShowChar] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const { state } = useContext(Store);
  const { characters, accessToken } = state;
  const [selectedChar, setSelectedChar] = useState([]);
  const [selectProf, setSelectProf] = useState(characters);
  const [Description, setDescription] = useState();
  const [scriptType, setScriptType] = useState("Normal");
  const [script, setScript] = useState([]);
  const [loading, setLoading] = useState(true);
  const [AddNewLine, setAddNewLine] = useState(initialState);
  const [videoSources, setVideoSources] = useState();
  const [greenScript, setGreenScript] = useState(false);
  const [loadingVideoScript, setLoadingVideoScript] = useState(false);
  const [lastVideo, setLastVideo] = useState([]);
  const [liveVideo, setLiveVideo] = useState("");
  const [videoData, setVideoData] = useState("");
  const [lastVideoLoader, setLastVideoLoader] = useState(true);
  const [showLastVideoPart, setShowLastVideoPart] = useState(false);
  const [CreateVideodisabled, setCreateVideodisabled] = useState(false);
  const [audioScriptLoader, setAudioScriptLoader] = useState(false);
  const [lastloadStop, setLastloadStop] = useState(false);
  const [downloadVideoLoader, setDownloadVideoLoader] = useState(false);
  const videoRef = useRef();

  function getToken() {
    return new Promise((resolve, reject) => {
      // Perform some asynchronous operation to retrieve the token
      // For example, an API call or an asynchronous function
      axios
        .get(GET_ACESS_TOKEN)
        .then((response) => resolve(response.data.access_token))
        .catch((error) => reject(error));
    });
  }

  const handlePauseAndPlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handlePreviousVideo = () => {
    setLastVideoLoader(false);
    const index = findLiveVideoIndex() - 1;
    if (index >= 0) {
      if (lastVideo[index]) {
        setLiveVideo(lastVideo[index]);
      }
    }

    // console.log(index);
  };
  const handleNextVideo = () => {
    setLastVideoLoader(false);
    const index = findLiveVideoIndex() + 1;
    if (index <= lastVideo.length) {
      if (lastVideo[index]) {
        setLiveVideo(lastVideo[index]);
      }
    }
  };

  function findLiveVideoIndex() {
    const rightIndex = lastVideo.length;
    for (let i = 0; i < rightIndex; i++) {
      if (lastVideo[i] === liveVideo) {
        return i;
      }
    }
  }

  function getImageSr(name) {
    for (let i = 0; i < characters.length; i++) {
      if (characters[i].name === name) {
        return characters[i].image;
      }
    }
  }
  function getImageOfEmotions(index) {
    if (script[index].emotionIcon) {
      return script[index].emotionIcon;
    } else {
      return plusIcon;
    }
  }
  function handleTheMenu(number) {
    const updatedScript = [...script];
    updatedScript[number].menu = !updatedScript[number].menu;
    setScript(updatedScript);
  }
  function handleAddingText(number) {
    const updatedScript = [...script];
    updatedScript[number].addChat = !updatedScript[number].addChat;
    setScript(updatedScript);
  }

  function handleDeleteingText(dialogue) {
    const updatedScript = script.filter((obj) => obj.dialogue !== dialogue);
    setScript(updatedScript);
  }

  function handleLeftingTextDown(number) {
    if (number < script.length - 1) {
      const updatedArray = [...script];
      [updatedArray[number], updatedArray[number + 1]] = [
        updatedArray[number + 1],
        updatedArray[number],
      ];
      setScript(updatedArray);
    }
  }
  function handleLeftingTextUp(number) {
    if (number > 0) {
      const updatedArray = [...script];
      [updatedArray[number], updatedArray[number - 1]] = [
        updatedArray[number - 1],
        updatedArray[number],
      ];
      setScript(updatedArray);
    }
  }

  function handleNewTextLine(characterName, image) {
    setAddNewLine({
      ...AddNewLine,
      name: characterName,
      image: image,
      showRightBar: true,
    });
  }

  function handleNewTextLineFunction(number) {
    const updatedArray = [...script];
    updatedArray[number].addChat = !updatedArray[number].addChat;

    // updatedArray.push(AddNewLine);

    updatedArray.splice(number + 1, 0, AddNewLine);
    setScript(updatedArray);
  }

  function handleTheEmotes(number) {
    const updatedScript = [...script];
    updatedScript[number].showEmotes = !updatedScript[number].showEmotes;
    setScript(updatedScript);
  }

  function handleTheRightMenu(number) {
    const updatedScript = [...script];
    updatedScript[number].showRightBar = !updatedScript[number].showRightBar;
    setScript(updatedScript);
  }

  function handleEmotionStatus(number, img, status) {
    const updatedScript = [...script];
    updatedScript[number].emotionIcon = img;
    updatedScript[number].emotion = status;
    setScript(updatedScript);
  }

  function handleDialog(e, index) {
    const updatedStories = [...script];
    // updatedStories[index].dialogue = e.target.value;
    updatedStories[index] = {
      ...updatedStories[index],
      dialogue: e.target.value,

      //other way to solve it

      //   const { value } = e.target;
      //   setData((prevData) => {
      //     const newData = [...prevData];
      //     newData[index] = { ...newData[index], name: value };
      //     return newData;
      //   });
    };

    setScript(updatedStories);
  }

  const selectCharacter = (char, index) => {
    const isCharacterSelected = selectedChar.some(
      (item) => item.name === char.name
    );

    if (isCharacterSelected) {
      const updatedData = selectedChar.filter(
        (item) => item.name !== char.name
      );
      setSelectedChar(updatedData);

      const updatedSelect = selectProf.map((item) =>
        item.name === char.name ? { ...item, selected: false } : item
      );
      setSelectProf(updatedSelect);
    } else {
      const updatedData = char;
      updatedData.uniqueNum = index;
      setSelectedChar((prev) => [...prev, updatedData]);

      const updatedSelect = selectProf.map((item) =>
        item.name === char.name ? { ...item, selected: true } : item
      );
      setSelectProf(updatedSelect);
    }
  };

  function CheckScriptValidity() {
    if (Description && selectedChar.length >= 2) {
      setGreenScript(true);
    }
  }

  const generateScriptChat = async () => {
    if (selectedChar.length >= 2 && Description.length > 3) {
      setGenerateScript(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      setLoading(true);
      await axios
        .post(
          GET_SCRIPT,
          { characters: selectedChar, topic: Description, tone: scriptType },
          { headers }
        )

        .then((response) => {
          if (response.data.script.length <= 1) {
            toast.error("Failed to generate the script try again!");
            // setLoading(true);
            // generateScriptChat();
          }
          setScript(response.data.script);
          // console.log(response.data.script);
        })
        .catch((error) => {
          return console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Select some characters or choose a topic");
    }
  };

  const generateVideoForOneText = async (index, name) => {
    // const updatedScript = [...script];
    // updatedScript[index].ProfileImage = getImageSr(name);
    // setScript(updatedScript);

    // setVideoIndex(index); needed

    if (script[0].audio) {
      setLoadingVideoScript(true);
      setShowVideo(true);

      const data = [
        {
          face: script[index].face,
          audio: script[index].audio,
          emotion: script[index].emotion ? script[index].emotion : "Calm",
        },
      ];

      const responseTimeout = 100000; // Timeout value in milliseconds (3 minutes)
      let timeoutId = null; // Timeout ID

      const connectWebSocket = () => {
        const ws = new WebSocket(GET_VIDEO_FOR_ONE);
        let responseReceived = false; // Flag to track if a response is received

        ws.onopen = () => {
          console.log("WebSocket is open");
          // loading_video = true;
          const request_data = { data: data };
          ws.send(JSON.stringify(request_data));
        };

        ws.onmessage = (event) => {
          responseReceived = true; // Set the flag to true when a response is received
          // clearTimeout(timeoutId!); // Clear the timeout since a response is received

          const response = JSON.parse(event.data);

          // console.log(response.result_video);
          if (response.error) {
            console.error("WebSocket error:", response.error);

            setLoadingVideoScript(false);
            toast.error("error", "Lost Connection to the server");
          } else {
            setLoadingVideoScript(false);
            if (response.result_video !== undefined) {
              // setVideoSources((prev) => [
              //   ...prev,
              //   `data:video/mp4;base64,${response.result_video}`,
              // ]);
              setVideoSources(`data:video/mp4;base64,${response.result_video}`);
            }
          }
        };

        ws.onclose = (event) => {
          console.log("WebSocket is closed:", event);
          setLoadingVideoScript(false);
        };

        ws.onerror = (event) => {
          console.error("WebSocket error:", event);
          setLoadingVideoScript(false);
        };

        // Set a timeout to handle the case where the server doesn't respond
        timeoutId = setTimeout(() => {
          if (!responseReceived) {
            ws.close();
            console.error("WebSocket response timeout");
            setLoadingVideoScript(false);

            toast.error("error", "Lost WebSocket response timeout");
          }
        }, responseTimeout);
      };

      connectWebSocket();
    } else {
      toast.error(
        "You can't create a video without creating the audio for the script"
      );
    }
  };

  const generateAudioForScript = async (script) => {
    try {
      const response = await axios.post(GET_SCRIPT_AUDIO, {
        name: script.name,
        index: script.index,
        dialogue: script.dialogue,
        gender: script.gender,
        emotion: script.emotion,
        refVoice: script.refVoice,
        image: script.image,
      });
      return response.data.data;
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      return null;
    }
  };

  const SendDataToWebSocket = async (data) => {
    const connectWebSocket = () => {
      const ws = new WebSocket(GET_VIDEO);
      setLastVideoLoader(true);
      const audioDataArray = [];
      ws.onopen = async (event) => {
        console.log("WebSocket is open");
        setLastVideoLoader(true);
        for (let i = 0; i < data.length; i++) {
          try {
            const updatedAudioData = await generateAudioForScript(data[i]);
            audioDataArray.push(updatedAudioData);
            // console.log(audioDataArray[i]);
            const requestData = { data: [audioDataArray[i]] };
            websocket.send(JSON.stringify(requestData));
            // Store the generated audio data in the array
          } catch (error) {
            console.error(
              `Error generating audio for script at index ${i}:`,
              error
            );
          }
        }
      };

      ws.onmessage = (event) => {
        // console.log('WebSocket message received:', event.data);
        const response = JSON.parse(event.data);
        // Send all the audio data stored in the array to the WebSocket server
        if (response.error) {
          console.error("WebSocket error:", response.error);
          setLastVideoLoader(false);
          toast.error("Lost Connection to the server");
        } else {
          setLastVideoLoader(false);

          if (response.result_video) {
            setLastVideo((prev) => [
              ...prev,
              `data:video/mp4;base64,${response.result_video}`,
            ]);
            setLiveVideo(`data:video/mp4;base64,${response.result_video}`);
          }
        }
      };

      ws.onclose = (event) => {
        console.log("WebSocket is closed:", event);
        setLastloadStop(true);
        setLastVideoLoader(false);
        toast.error("Ping Timeout");
      };

      ws.onerror = (event) => {
        console.error("WebSocket error:", event);
        setLastVideoLoader(false);
      };

      return ws;
    };

    const websocket = connectWebSocket();
  };

  const generateVideoData = async () => {
    setLastVideo([]);
    // if (script[0].audio) {
    setShowLastVideoPart(true);
    setLastVideoLoader(true);
    const updatedScript = [];
    const updatedVideoData = [];

    for (let i = 0; i < script.length; i++) {
      updatedScript.push({
        ...script[i],
        ProfileImage: getImageSr(script[i].name),
      });
    }

    setScript(updatedScript);

    await Promise.all([updatedScript]);
    SendDataToWebSocket(updatedScript);
    setCreateVideodisabled(true);
  };

  const generateRandomTopic = async () => {
    const prompt = "Generate a random topic for a conversation:";

    try {
      const response = await axios.post(GET_RANDOM_TOPIC, {
        prompt: prompt,
      });

      setDescription(response.data.response.comp);
    } catch (error) {
      console.log(error);
    }
  };

  const DownloadVideo = async () => {
    if (lastVideo.length >= 1) {
      toast.success("The Video is being downloaded");
      const data = [...lastVideo];
      // console.log(data);

      try {
        setDownloadVideoLoader(true);
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        };

        const response = await axios.post(
          GET_DOWNLOADED_VIDEO,
          {
            data_urls: data,
          },
          headers //not needed
        );

        const link = document.createElement("a");
        link.href = response.data.data_url;
        link.download = "output.mp4";
        link.click();

        setDownloadVideoLoader(false);
      } catch (error) {
        toast.error("error", "Failed to download the video.");
        console.error(error);
      }
    } else {
      toast.error("No video loaded yet to be downloaded");
    }
  };

  const SaveAllShow = () => {
    console.log(lastVideo);
    console.log(selectProf);
    console.log(script);
  };

  useEffect(() => {
    const previousLength = lastVideo.length;

    // Check if something new has been added
    if (previousLength !== 0 && lastVideo.length > previousLength) {
      setLastVideoLoader(false);
    }

    if (lastVideo.length === script.length) {
      setCreateVideodisabled(false);
    }
  }, [lastVideo]);

  useEffect(() => {
    if (script) {
      const newArray = script.map((obj) => {
        return {
          ...obj,
          menu: false,
          addChat: false,
          showEmotes: false,
          showRightBar: true,
          emotionIcon: false,
          // emotion: "",
          ProfileImage: "",
        };
      });

      setScript(newArray);
    }
  }, [loading]);

  useEffect(() => {
    if (script) {
      const newArray = script.map((obj) => {
        return {
          ...obj,
          menu: false,
          addChat: false,
          showEmotes: false,
          showRightBar: true,
          emotionIcon: false,
          // emotion: "",
          // ProfileImage: "",
        };
      });

      setScript(newArray);
    }
  }, [audioScriptLoader]);

  useEffect(() => {
    if (Description && selectedChar.length >= 2) {
      setGreenScript(true);
    } else {
      setGreenScript(false);
    }
  }, [selectedChar, Description]);

  return (
    <Grid
      item
      container
      direction="column"
      marginLeft={{ xl: 20, lg: 10, md: 5, xs: 5 }}
      marginTop={10}
      width="85%"
    >
      {/* {console.log(script)} */}
      {console.log(CreateVideodisabled)}
      {console.log(lastVideo.length)}
      {console.log(script.length)}

      <ToastContainer position="top-center" theme="dark" color="yellow" />

      <Grid item color="white" fontSize={30} fontWeight="750" marginBottom={3}>
        Create Script
      </Grid>
      <Grid
        item
        container
        direction="column"
        paddingLeft={4}
        paddingTop={3}
        paddingBottom={7}
        spacing={1}
        className={classes["ScriptCard"]}
      >
        <Grid item container direction="row" alignItems="center" gap={3}>
          <Grid item xs={12} sm={12} md={12} lg={1.5} paddingBottom={1.6}>
            Characters:
          </Grid>
          {selectProf.map((char, index) => (
            <Grid
              item
              marginRight={{ xl: 0.1, lg: 0.1, md: 0.1, sm: 0.1, xs: 12 }}
              container
              direction="row"
              alignContent="center"
              className={
                char.selected
                  ? classes["ScriptCharactersDetailsActive"]
                  : classes["ScriptCharactersDetails"]
              }
              width={{ xs: 160, md: 160, lg: 160, xl: 160 }}
              key={index}
              onClick={() => selectCharacter(char, index)}
            >
              <Grid item paddingTop={0.5} xs={3.4}>
                <img
                  src={char.image}
                  className={classes["ScriptProfile"]}
                  alt="imageSample"
                />
              </Grid>
              <Grid item color="white" fontSize="15px" paddingTop={1.5}>
                {char.name}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item container marginBottom={3.5}>
          Script Type:
          <Grid item width="1000px">
            <select
              value={scriptType}
              onChange={(e) => setScriptType(e.target.value)}
              className={classes["ScriptSelectForm"]}
            >
              <option>Normal</option>
              <option>Deep</option>
              <option>Funny</option>
              <option>Intellectual</option>
            </select>
          </Grid>
        </Grid>
        <Grid item>Description:</Grid>
        <Grid item container direction="row">
          <Grid xs={10} sm={10} md={11} lg={11} item paddingRight={0}>
            <textarea
              value={Description}
              onChange={(e) => {
                setDescription(e.target.value);
                CheckScriptValidity();
              }}
              className={classes["ScriptTextArea"]}
            ></textarea>
          </Grid>
          <Grid item xs={0.5} marginLeft={2} marginTop={4}>
            <LightbulbIcon
              className={classes["ScriptBulb"]}
              onClick={() => generateRandomTopic()}
              fontSize="large"
            ></LightbulbIcon>
          </Grid>
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Grid item marginRight={{ xl: 17, lg: 17, md: 17, xs: 11.5 }}>
            <button
              className={
                greenScript
                  ? classes["ScriptActiveButtonGreen"]
                  : classes["ScriptActiveButton"]
              }
              onClick={() => {
                generateScriptChat();
              }}
            >
              Draft Script
            </button>
          </Grid>
        </Grid>
        {generateScript ? (
          <Grid item container direction="column">
            <Grid item marginBottom={1.5}>
              AI Script:
            </Grid>
            <Grid item container marginRight={0} direction="row">
              <Grid
                item
                container
                direction="column"
                paddingLeft={4}
                paddingTop={3}
                paddingBottom={7}
                spacing={1}
              >
                <Grid
                  item
                  container
                  direction="row"
                  className={classes["EditTextCard"]}
                  // height="1000px"
                  width="90%"
                  // xs={5}
                  marginTop={3}
                >
                  {!loading && script ? (
                    script.map((oneScript, index) => (
                      <Grid
                        item
                        container
                        direction="row"
                        marginTop={1.5}
                        alignItems="center"
                        key={index}
                        marginBottom={{ xs: 1, sm: 1, md: 1, lg: 0.1 }}
                        marginLeft={{ xs: 3, sm: 4, md: 4, lg: 0 }}
                      >
                        {script[index].menu ? (
                          <>
                            <Grid item marginRight={1}>
                              <img
                                src={addChatIcon}
                                onClick={() => {
                                  setEditChat(!editChat);
                                  handleAddingText(index);
                                }}
                                alt="addChatIcon"
                              />
                            </Grid>
                            <Grid item marginRight={1}>
                              <img
                                src={deleteChat}
                                onClick={() => {
                                  handleDeleteingText(oneScript.dialogue);
                                }}
                                alt="deleteChat"
                              />
                            </Grid>
                            <Grid
                              xs={0.5}
                              item
                              container
                              direction="column"
                              alignItems="center"
                              height="120px"
                            >
                              <Grid item height="40px" paddingTop={3}>
                                <img
                                  src={arrowUp}
                                  onClick={() => handleLeftingTextUp(index)}
                                  alt="arrowUp"
                                />
                              </Grid>
                              <Grid item height="35px">
                                <img
                                  onClick={() => {
                                    handleTheMenu(index);
                                    handleTheRightMenu(index);
                                  }}
                                  src={backMenu}
                                  alt="backMenu"
                                />
                              </Grid>
                              <Grid item height="30px">
                                <img
                                  src={arrowDwon}
                                  onClick={() => handleLeftingTextDown(index)}
                                  alt="arrowDwon"
                                />
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <Grid
                            item
                            color="white"
                            bgcolor="black"
                            borderRadius={10}
                            fontSize="small"
                            height={24}
                            marginRight={{ xs: 12, sm: 12, md: 20, lg: 2 }}
                            marginLeft={{ xs: 8, sm: 8, md: 8, lg: 0 }}
                            marginBottom={1}
                          >
                            <TuneIcon
                              onClick={() => {
                                handleTheMenu(index);
                                handleTheRightMenu(index);
                              }}
                            ></TuneIcon>
                          </Grid>
                        )}

                        <Grid
                          item
                          container
                          direction="row"
                          className={classes["EditScriptCharactersChat"]}
                          marginRight={2}
                          width="70%"
                          height={{
                            xs: "150px",
                            sm: "150px",
                            md: "130px",
                            lg: "66px",
                          }}
                        >
                          <Grid
                            item
                            paddingTop={1.2}
                            paddingLeft={2}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={1.5}
                          >
                            <img
                              src={
                                oneScript.image
                                  ? oneScript.image
                                  : oneScript.face
                              }
                              className={classes["EditScriptProfile"]}
                              alt="imageSample"
                            />
                          </Grid>
                          <Grid
                            item
                            color="white"
                            // fontSize="15px"
                            // marginLeft={1}
                            paddingTop={1}
                            // xs={10}
                            height="100%"
                            width="85%"
                          >
                            <textarea
                              className={classes["EditScriptInput"]}
                              value={oneScript.dialogue}
                              type="text"
                              onChange={(e) => handleDialog(e, index)}
                            ></textarea>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          container
                          direction="row"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={2.5}
                          paddingTop={1}
                          // marginLeft={{ xs: 0, sm: 0 }}
                        >
                          {script[index].showEmotes ? (
                            <Grid
                              item
                              container
                              height="25px"
                              width="85%"
                              className={classes["EditScriptEmotions"]}
                              gap={1.1}
                            >
                              <Grid item fontSize="20px" marginRight={0.7}>
                                <img
                                  src={smileIcon}
                                  width="20px"
                                  height="18px"
                                  alt="smileIcon"
                                  onClick={() => {
                                    handleEmotionStatus(
                                      index,
                                      smileIcon,
                                      "Happy"
                                    );
                                    handleTheEmotes(index);
                                  }}
                                ></img>
                              </Grid>
                              <Grid item fontSize="20px" marginRight={0.7}>
                                <img
                                  src={sadIcon}
                                  width="20px"
                                  height="18px"
                                  alt="sadIcon"
                                  onClick={() => {
                                    handleEmotionStatus(index, sadIcon, "Sad");
                                    handleTheEmotes(index);
                                  }}
                                ></img>
                              </Grid>
                              <Grid item fontSize="20px" marginRight={0.7}>
                                <img
                                  src={angryIcon}
                                  width="20px"
                                  height="18px"
                                  alt="angryIcon"
                                  onClick={() => {
                                    handleEmotionStatus(
                                      index,
                                      angryIcon,
                                      "Angry"
                                    );
                                    handleTheEmotes(index);
                                  }}
                                ></img>
                              </Grid>
                              <Grid item fontSize="20px" marginRight={0.7}>
                                <img
                                  src={relievedIcon}
                                  width="20px"
                                  height="18px"
                                  alt="relievedIcon"
                                  onClick={() => {
                                    handleEmotionStatus(
                                      index,
                                      relievedIcon,
                                      "Calm"
                                    );
                                    handleTheEmotes(index);
                                  }}
                                ></img>
                              </Grid>
                              <Grid item fontSize="20px" marginRight={0.7}>
                                <img
                                  src={dislikeIcon}
                                  width="20px"
                                  height="18px"
                                  alt="dislikeIcon"
                                  onClick={() => {
                                    handleEmotionStatus(
                                      index,
                                      dislikeIcon,
                                      "Refuse"
                                    );
                                    handleTheEmotes(index);
                                  }}
                                ></img>
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {script[index].showRightBar ? (
                            <Grid
                              item
                              marginLeft={2.5}
                              container
                              direction="row"
                            >
                              <Grid
                                item
                                color="#707070"
                                marginRight={2.5}
                                paddingBottom={1}
                              >
                                <img
                                  onClick={() => handleTheEmotes(index)}
                                  src={getImageOfEmotions(index)}
                                  alt="plusIcon"
                                  width="20px"
                                  height="18px"
                                  style={{ marginBottom: "3px" }}
                                ></img>
                              </Grid>
                              <Grid item color="#707070" marginRight={2.5}>
                                <ChatBubbleIcon></ChatBubbleIcon>
                              </Grid>
                              <Grid item color="#707070">
                                <TheatersIcon
                                  onClick={() => {
                                    // setShowVideo(true);
                                    generateVideoForOneText(
                                      index,
                                      oneScript.character
                                    );
                                  }}
                                ></TheatersIcon>
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        {script[index].addChat ? (
                          <Grid
                            item
                            container
                            direction="row"
                            className={classes["EditScriptCharactersChat"]}
                            height={{
                              xs: "200px",
                              sm: "150px",
                              md: "64px",
                            }}
                            marginLeft={{
                              sx: 0,
                              sm: 0,
                              md: 0,
                              lg: 5,
                              xl: 15,
                            }}
                            marginRight={{
                              sx: 20,
                              sm: 12,
                              md: 0,
                              lg: 5,
                              xl: 25,
                            }}
                            xs={12}
                          >
                            <Grid
                              item
                              paddingTop={1.2}
                              paddingLeft={2}
                              sx={12}
                              sm={12}
                              md={1.2}
                            >
                              <img
                                src={AddNewLine.image}
                                className={classes["EditScriptProfile"]}
                                alt="questionIcon"
                                onClick={() => setShowChar(true)}
                              />
                            </Grid>
                            {showChar ? (
                              <>
                                {selectedChar.map((char) => (
                                  <Grid
                                    item
                                    paddingTop={1.2}
                                    paddingLeft={2}
                                    xs={1.2}
                                    key={char.name}
                                  >
                                    <img
                                      src={char.image}
                                      className={classes["EditScriptProfile"]}
                                      alt="questionIcon"
                                      onClick={() => {
                                        setShowChar(false);
                                        handleNewTextLine(
                                          char.name,
                                          char.image
                                        );
                                      }}
                                    />
                                  </Grid>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                            {showChar ? (
                              <></>
                            ) : (
                              <Grid
                                item
                                color="white"
                                fontSize="15px"
                                marginLeft={1}
                                paddingTop={1.7}
                                direction="row"
                                // xs={5}
                              >
                                <Grid item container direction="row">
                                  <Grid
                                    xs={9}
                                    sm={10}
                                    // md={5}
                                    // lg={5}
                                    // xl={}
                                    width={{
                                      sx: 50,
                                      sm: 100,
                                      md: 200,
                                      lg: 400,
                                      xl: 400,
                                    }}
                                    item
                                  >
                                    <textarea
                                      className={classes["EditScriptInput"]}
                                      placeholder="Add more Messages"
                                      onChange={(e) =>
                                        setAddNewLine({
                                          ...AddNewLine,
                                          dialogue: e.target.value,
                                        })
                                      }
                                    ></textarea>
                                  </Grid>

                                  <Grid item xs={12} sm={0.1} marginLeft={1}>
                                    <button
                                      onClick={() =>
                                        handleNewTextLineFunction(index)
                                      }
                                      className={classes["ScriptAddTextButton"]}
                                    >
                                      add
                                    </button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    ))
                  ) : (
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignContent="center"
                    >
                      <Grid item>
                        <RiseLoader
                          color={"#F0FD00"}
                          loading={loading}
                          size={35}
                          speedMultiplier={0.8}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {/* <Grid item>
                  <button
                    // onClick={() => generateScriptAudio()}
                    className={classes["ScriptExtraButton2"]}
                  >
                    create/update the audio
                  </button>
                  {audioScriptLoader ? <Loader /> : <></>}
                </Grid> */}
              </Grid>
              {showVideo ? (
                <Grid
                  item
                  container
                  justifyContent={{ sm: "none", md: "center" }}
                  alignContent={{ sm: "none", md: "center" }}
                  className={classes["ScriptOfLastVideo"]}
                  width="50%"
                  marginLeft={{ xs: 10, sm: 20, md: 30, lg: 40, xl: 40 }}
                >
                  <Grid
                    width="100%"
                    marginLeft={5}
                    marginRight={5}
                    marginTop={{ xs: 5, sm: 5, md: 0.1 }}
                  >
                    {loadingVideoScript ? (
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignContent="center"
                      >
                        <Grid item>
                          <FadeLoader
                            color={"#F0FD00"}
                            loading={lastVideoLoader}
                            size={1000}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <video
                        controls
                        autoPlay
                        className={classes["ScriptVideoLast"]}
                        ref={videoRef}
                        // src={videoSources[VideoIndex]}
                        src={videoSources}
                      ></video>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            {showLastVideoPart ? (
              <>
                {" "}
                <Grid item marginBottom={1.5}>
                  Created Video:
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={{ sm: "none", md: "center" }}
                  alignContent={{ sm: "none", md: "center" }}
                  className={classes["ScriptOfLastVideo"]}
                  width="50%"
                  marginLeft={{ xs: 10, sm: 20, md: 30, lg: 40, xl: 40 }}
                >
                  <Grid
                    width="100%"
                    marginLeft={5}
                    marginRight={5}
                    marginTop={{ xs: 5, sm: 5, md: 0.1 }}
                  >
                    {lastVideoLoader ? (
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignContent="center"
                        marginTop={20}
                        paddingBottom={25}
                      >
                        <Grid item>
                          <FadeLoader
                            color={"#F0FD00"}
                            loading={lastVideoLoader}
                            size={1000}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <video
                        autoPlay
                        controls
                        className={classes["ScriptVideoLast"]}
                        ref={videoRef}
                        src={liveVideo}
                        // src={liveVideo[currentVideoIndex]}
                        onEnded={() => {
                          const index = findLiveVideoIndex();
                          if (!lastloadStop) {
                            if (lastVideo[index + 1]) {
                              setLiveVideo(lastVideo[index + 1]);
                            } else if (lastVideo.length === script.length) {
                              setLastVideoLoader(false);
                            } else if (lastVideo.length < script.length + 1) {
                              setLastVideoLoader(true);
                            }
                          } else {
                            setLastVideoLoader(false);
                          }
                        }}
                      ></video>
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    className={classes["ScriptVideoButtons"]}
                    justifyContent="space-between"

                    // gap={}
                  >
                    <Grid item>
                      <SkipPreviousIcon
                        onClick={() => handlePreviousVideo()}
                      ></SkipPreviousIcon>
                    </Grid>
                    <Grid item>
                      <PauseIcon onClick={handlePauseAndPlay}></PauseIcon>
                    </Grid>
                    <Grid item>
                      <SkipNextIcon
                        onClick={() => handleNextVideo()}
                      ></SkipNextIcon>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Grid item container justifyContent="flex-end" marginTop={5}>
              <Grid item marginRight={6}>
                {lastVideo.length >= 1 ? (
                  <button
                    onClick={() => {
                      DownloadVideo();
                    }}
                    className={classes["ScriptExtraButton"]}
                  >
                    {downloadVideoLoader ? (
                      <BarLoader
                        color={"black"}
                        // loading={loading}
                        size={35}
                        speedMultiplier={0.8}
                      />
                    ) : (
                      <> Download Videos</>
                    )}
                  </button>
                ) : (
                  <></>
                )}
                {/* <button
                  onClick={() => {
                    SaveAllShow();
                  }}
                  className={classes["ScriptExtraButton"]}
                >
                  Save Show
                </button> */}
                <button
                  onClick={() => {
                    generateVideoData();
                  }}
                  disabled={CreateVideodisabled}
                  className={
                    CreateVideodisabled
                      ? classes["ScriptExtraButtonDisable"]
                      : classes["ScriptExtraButton"]
                  }
                >
                  Create Video
                </button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default Script;
