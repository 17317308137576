import React from "react";
import classes from "../Assets/Styles/Shows.module.css";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import TabPanel from "../Components/Shows/TabPanel";

// className={classes["headerLogo"]}
function Shows() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    <Grid item container direction="column" alignItems="center" marginTop={10}>
      <Grid item container width="80%">
        {console.log(value)}
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "white",
            display: "flex",
            height: 700,
            borderRadius: 10,
            color: "green",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider", color: "green" }}
          >
            <Tab label="Item One" {...a11yProps(0)} />
            <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} />
            <Tab label="Item Four" {...a11yProps(3)} />
            <Tab label="Item Five" {...a11yProps(4)} />
            <Tab label="Item Six" {...a11yProps(5)} />
            <Tab label="Item Seven" {...a11yProps(6)} />
            <Tab label="Item Seven" {...a11yProps(6)} />
            <Tab label="Item Seven" {...a11yProps(6)} />
            <Tab label="Item Seven" {...a11yProps(6)} />
            <Tab label="Item Seven" {...a11yProps(6)} />
            <Tab label="Item Seven" {...a11yProps(6)} />
            <Tab label="Item Seven" {...a11yProps(6)} />
            <Tab label="Item Seven" {...a11yProps(6)} />
            <Tab label="Item Seven" {...a11yProps(6)} />
            <Tab label="Item Seven" {...a11yProps(6)} />
            <Tab label="Item Seven" {...a11yProps(6)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Grid item container bgcolor="red" width="200px">
              hi
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
          <TabPanel value={value} index={3}>
            Item Four
          </TabPanel>
          <TabPanel value={value} index={4}>
            Item Five
          </TabPanel>
          <TabPanel value={value} index={5}>
            Item Six
          </TabPanel>
          <TabPanel value={value} index={6}>
            Item Seven
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Shows;
