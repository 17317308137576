import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAuth } from "firebase/auth";
// import { getFirestore } from 'firebase/firestore';
// import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  projectId: "hookline-magic-f926f",
  appId: "1:118892210005:web:6148b5504af046bb98f1dc",
  databaseURL: "https://hookline-magic-f926f-default-rtdb.firebaseio.com",
  storageBucket: "hookline-magic-f926f.appspot.com",
  locationId: "us-central",
  apiKey: "AIzaSyCmxgANCVzw4Vgw-c1AtaRztVlMjX_YNiM",
  authDomain: "hookline-magic-f926f.firebaseapp.com",
  messagingSenderId: "118892210005",
  measurementId: "G-YHSB4RD858",
};

const app = initializeApp(firebaseConfig);

// export const db = getFirestore(app);
// export const storage = getStorage(app);

export const auth = getAuth(app);
