import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Screens/Home";
import Header from "./Header";
import { Box } from "@mui/material";
import CreatedSample from "../Screens/CreatedSample";
import SigninScreen from "../Screens/SigninScreen";
import SignupScreen from "../Screens/SignupScreen";
import Script from "../Screens/Script";
import TestingScreen from "../Screens/TestingScreen";
import Shows from "../Screens/Shows";

const Navigate = () => {
  return (
    <BrowserRouter>
      <Box>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/createdsample" element={<CreatedSample />} />
          <Route path="/signin" element={<SigninScreen />} />
          <Route path="/signup" element={<SignupScreen />} />
          <Route path="/script" element={<Script />} />
          <Route path="/shows" element={<Shows />} />
          <Route path="/testing" element={<TestingScreen />} />
        </Routes>
      </Box>
    </BrowserRouter>
  );
};

export default Navigate;
