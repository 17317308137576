import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "../Assets/Styles/Header.module.css";
import { Avatar, Button, Grid, Menu, MenuItem } from "@mui/material";
import logo from "../Assets/Images/logo.png";
import question from "../Assets/Images/question.png";
import { Store } from "../Store";

function Header() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const signoutHandler = () => {
    ctxDispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    localStorage.removeItem("sampleCreatedData");
    navigate("/");
  };

  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ToMyShows = () => {
    navigate("/shows");
  };

  return (
    <Grid
      item
      container
      className={classes["headerContainer"]}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item marginLeft={2} className={classes["headerLogo"]}>
        <Link to={"/"}>
          <img src={logo} className={classes["headerImage"]} alt="logo" />
        </Link>
      </Grid>
      {/* {userInfo ? (
        <Grid
          item
          xs={1.6}
          container
          color="white"
          direction="row"
          gap={1.4}
          alignItems="center"
        >
          <Avatar />

          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{ color: "#e0d324 " }}
          >
            {userInfo.email}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
              style: {
                width: 190,
                backgroundColor: "grey",
                color: "white",
              },
            }}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                ToMyShows();
              }}
            >
              My Show
            </MenuItem>
            <MenuItem onClick={handleClose}>About Us</MenuItem>
            <MenuItem>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/"
                onClick={signoutHandler}
              >
                Sign out
              </Link>
            </MenuItem>
          </Menu>
        </Grid>
      ) : (
        <Grid
          item
          xs={1.4}
          container
          gap={4}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Link className={classes["siginButton"]} to="/signin">
              Signin
            </Link>
          </Grid>

          <button disabled={true} className={classes["HomeButton"]}>
            <Link
              disabled={true}
              style={{ textDecoration: "none", color: "#383838" }}
              to="/signup"
            >
              Signup
            </Link>
            Signup
          </button>
        </Grid>
      )} */}
    </Grid>
  );
}

export default Header;
