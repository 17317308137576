import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "../Assets/Styles/Testing.module.css";
import PersonIcon from "@mui/icons-material/Person";
import TheatersIcon from "@mui/icons-material/Theaters";
import MovieCreationIcon from "@mui/icons-material/MovieCreation";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import PreviewIcon from "@mui/icons-material/Preview";
import Person from "../Assets/Images/Group 1045.png";
import Person2 from "../Assets/Images/charNew.png";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
const TestingScreen = () => {
  const [choice, setChoice] = useState("characterMode");

  return (
    <Grid item container justifyContent="center">
      {console.log(choice)}
      <Grid item container xs={8.5} marginTop={7}>
        <Grid item>
          <PersonIcon fontSize="large" className={classes["HomeProfileIcon"]} />
        </Grid>
        <Grid item bgcolor="grey" className={classes["HomeProjectLibrary"]}>
          Project Library
        </Grid>
      </Grid>
      <Grid item container xs={8.1}>
        <Grid item container gap={3} marginTop={1}>
          <Grid item bgcolor="white" className={classes["HomeNameProject"]}>
            Name Project: input
          </Grid>
          <Grid
            item
            bgcolor="yellow"
            className={classes["HomeDescribeProject"]}
          >
            Describe Project:
            <input syle={{ color: "red" }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        direction="row"
        marginTop={4}
      >
        <Grid
          item
          container
          className={classes[""]}
          bgcolor="purple"
          xs={4}
          direction="row"
          marginRight={3}
        >
          <Grid
            xs={1}
            item
            container
            direction="column"
            bgcolor="grey"
            alignContent="center"
            gap={8}
          >
            <Grid
              item
              color={() => (choice === "characterMode" ? "yellow" : "black")}
              marginTop={3}
            >
              <PersonIcon
                fontSize="large"
                onClick={() => setChoice("characterMode")}
              ></PersonIcon>
            </Grid>
            <Grid
              item
              color={() => (choice === "movieMode" ? "yellow" : "black")}
            >
              <MovieCreationIcon
                onClick={() => setChoice("movieMode")}
                fontSize="large"
              ></MovieCreationIcon>
            </Grid>
            <Grid
              item
              color={() => (choice === "editMode" ? "yellow" : "black")}
            >
              <TheatersIcon
                onClick={() => setChoice("editMode")}
                fontSize="large"
              ></TheatersIcon>
            </Grid>
            <Grid
              item
              color={() => (choice === "scoreMode" ? "yellow" : "black")}
            >
              <MusicNoteIcon
                onClick={() => setChoice("scoreMode")}
                fontSize="large"
              ></MusicNoteIcon>
            </Grid>
            <Grid
              item
              color={() => (choice === "polishMode" ? "yellow" : "black")}
              marginBottom={3}
            >
              <PreviewIcon
                fontSize="large"
                onClick={() => setChoice("polishMode")}
              ></PreviewIcon>
            </Grid>
          </Grid>
          <Grid xs={9} item container>
            {(() => {
              if (choice === "characterMode") {
                return (
                  <Grid item container>
                    <Grid
                      item
                      container
                      marginLeft={3}
                      // direction="row"
                      marginTop={1.5}
                    >
                      <Grid
                        item
                        container
                        // xs={12}
                        // sm={1.4}
                        // md={1.4}
                        // lg={1.2}
                        xl={0.8}
                        direction="column"
                        alignItems="center"
                        alignContent="center"
                        marginRight={5}

                        // key={index}
                      >
                        <Grid item>
                          <img
                            // src={getImageSr(character.name, index)}
                            src={Person2}
                            // onClick={() => ChangeFocus(index)}
                            className={
                              // character.name === focus
                              true
                                ? classes["SmapleProfileActive"]
                                : classes["SmapleProfile"]
                            }
                            alt="Person"
                          />
                        </Grid>
                        <Grid
                          item
                          className={
                            // character.name === focus
                            true
                              ? classes["SmapleAvatarNameActive"]
                              : classes["SmapleAvatarName"]
                          }
                        >
                          {/* {character.name} */}
                          Wissam
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        // xs={12}
                        // sm={1.4}
                        // md={1.4}
                        // lg={1.2}
                        xl={0.8}
                        direction="column"
                        alignItems="center"
                        alignContent="center"
                        marginRight={5}

                        // key={index}
                      >
                        <Grid item>
                          <img
                            // src={getImageSr(character.name, index)}
                            src={Person2}
                            // onClick={() => ChangeFocus(index)}
                            className={
                              // character.name === focus
                              true
                                ? classes["SmapleProfileActive"]
                                : classes["SmapleProfile"]
                            }
                            alt="Person"
                          />
                        </Grid>
                        <Grid
                          item
                          className={
                            // character.name === focus
                            true
                              ? classes["SmapleAvatarNameActive"]
                              : classes["SmapleAvatarName"]
                          }
                        >
                          {/* {character.name} */}
                          Wissam
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        // xs={12}
                        // sm={1.4}
                        // md={1.4}
                        // lg={1.2}
                        xl={0.8}
                        direction="column"
                        alignItems="center"
                        alignContent="center"
                        marginRight={5}

                        // key={index}
                      >
                        <Grid item>
                          <img
                            // src={getImageSr(character.name, index)}
                            src={Person2}
                            // onClick={() => ChangeFocus(index)}
                            className={
                              // character.name === focus
                              true
                                ? classes["SmapleProfileActive"]
                                : classes["SmapleProfile"]
                            }
                            alt="Person"
                          />
                        </Grid>
                        <Grid
                          item
                          className={
                            // character.name === focus
                            true
                              ? classes["SmapleAvatarNameActive"]
                              : classes["SmapleAvatarName"]
                          }
                        >
                          {/* {character.name} */}
                          Wissam
                        </Grid>
                      </Grid>
                      <Grid item marginTop={2}>
                        <AddCircleIcon fontSize="large" />
                      </Grid>
                    </Grid>
                    <Grid item container marginLeft={2}>
                      <Grid
                        item
                        className={classes["HomeCharacterDetails"]}
                        bgcolor="yellow"
                      >
                        hi
                      </Grid>
                      <Grid
                        item
                        container
                        alignItems="center"
                        color="yellow"
                        gap={1}
                      >
                        <Grid item>
                          <ChangeCircleIcon fontSize="large" />
                        </Grid>{" "}
                        <Grid item> New Bio</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              } else if (choice === "movieMode") {
                return <p>movieMode</p>;
              } else if (choice === "editMode") {
                return <p>editMode</p>;
              } else if (choice === "scoreMode") {
                return <p>scoreMode</p>;
              } else if (choice === "polishMode") {
                return <p>polishMode</p>;
              }
            })()}
          </Grid>
        </Grid>
        <Grid container item xs={4} marginLeft={0}>
          {(() => {
            if (choice === "characterMode") {
              return <Grid></Grid>;
            } else if (choice === "movieMode") {
              return (
                <Grid item container bgcolor="green">
                  movieMode
                </Grid>
              );
            } else if (choice === "editMode") {
              return (
                <Grid item container bgcolor="green">
                  editMode
                </Grid>
              );
            } else if (choice === "scoreMode") {
              return (
                <Grid item container bgcolor="green">
                  editMode
                </Grid>
              );
            } else if (choice === "polishMode") {
              return (
                <Grid item container bgcolor="green">
                  polishMode
                </Grid>
              );
            }
          })()}
        </Grid>
      </Grid>
      <Grid item container bgcolor="grey" xs={8.2} marginTop={3}>
        {(() => {
          if (choice === "characterMode") {
            return (
              <Grid item container>
                <Grid
                  item
                  container
                  xs={1.2}
                  padding={1}
                  gap={2.5}
                  direction="column"
                  sx={{ border: "2px solid black" }}
                  // className={classes["HomecharacterModeButtonBox"]}
                >
                  <Grid item>
                    <button className={classes["HomeButton"]}>voice</button>
                  </Grid>
                  <Grid item>
                    <button className={classes["HomeButton"]}>Look</button>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  bgcolor="black"
                  width="100%"
                  height="200px"
                >
                  hi
                </Grid>
              </Grid>
            );
          } else if (choice === "movieMode") {
            return (
              <Grid item container>
                <Grid
                  item
                  container
                  xs={1.2}
                  padding={1}
                  gap={2.5}
                  direction="column"
                  sx={{ border: "2px solid black" }}
                >
                  <Grid item>
                    <button className={classes["HomeButton"]}>voice</button>
                  </Grid>
                  <Grid item>
                    <button className={classes["HomeButton"]}>Look</button>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  bgcolor="white"
                  width="100%"
                  height="200px"
                >
                  hi
                </Grid>
              </Grid>
            );
          } else if (choice === "editMode") {
            return (
              <Grid item container>
                editMode
              </Grid>
            );
          } else if (choice === "scoreMode") {
            return (
              <Grid item container>
                scoreMode
              </Grid>
            );
          } else if (choice === "polishMode") {
            return (
              <Grid item container>
                polishMode
              </Grid>
            );
          }
        })()}
      </Grid>
    </Grid>
  );
};

export default TestingScreen;
