import { useEffect } from "react";
import "./App.css";
import Navigate from "./Navigates/Navigate";
import WebFont from "webfontloader";

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto", "Great Vibes", "Nunito", "PlayfairDisplay"],
      },
    });
  }, []);
  return (
    <div className="App">
      <Navigate />
    </div>
  );
}
export default App;
