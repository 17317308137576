export const GET_USERS = "/prestiges";

export const GET_COURSES = "/course";

export const GET_SESSIONS = "/session";

// export const GET_CHARACTERS =
//   "https://us-central1-hookline-magic-f926f.cloudfunctions.net/openaiApi";

export const GET_CHARACTERS =
  "  https://us-central1-hookline-magic-f926f.cloudfunctions.net/characterCreatorApi";

export const GET_ANOTHER_BIO =
  "https://us-central1-hookline-magic-f926f.cloudfunctions.net/openAiBio";

export const GET_RANDOM_TOPIC =
  "https://us-central1-hookline-magic-f926f.cloudfunctions.net/openAiSandbox";

// export const GET_SCRIPT =
//   "https://us-central1-hookline-magic-f926f.cloudfunctions.net/generateDialogue";

export const GET_SCRIPT =
  "https://us-central1-hookline-magic-f926f.cloudfunctions.net/draftScript";

// export const GET_SCRIPT_AUDIO =
//   "https://us-central1-hookline-magic-f926f.cloudfunctions.net/generateAudio";

export const GET_SCRIPT_AUDIO = "http://35.219.149.179:5000/synthesize";

// export const GET_SCRIPT =
// "https://us-central1-hookline-magic-f926f.cloudfunctions.net/generateScript";

// export const GET_SCRIPT =
// "https://us-central1-hookline-magic-f926f.cloudfunctions.net/openaiChatApi";

// export const GET_GENERATE_TOKEN = "http://34.101.123.75:5000";

// export const GET_ACESS_TOKEN = "http://34.116.167.26:9000/get_access_token";

// export const GET_IMAGE = "http://34.101.123.75:5000";

// export const GET_VIDEO_FOR_ONE = "ws://34.116.167.26:8765";

// export const GET_VIDEO = "ws://34.116.167.26:8765";

// export const GET_DOWNLOADED_VIDEO = "http://34.116.167.26:9000/concat_videos";

export const GET_GENERATE_TOKEN = "http://35.208.23.250:5000";
export const GET_IMAGE = "http://35.208.23.250:5000";

export const GET_ACESS_TOKEN = "http://35.211.111.101:9000/get_access_token";
export const GET_DOWNLOADED_VIDEO = "http://35.211.111.101:9000/concat_videos";

export const GET_VIDEO_FOR_ONE = "ws://35.211.111.101:8765";
export const GET_VIDEO = "ws://35.211.111.101:8765";
// export const GET_VIDEO = "ws://localhost:8765";
