import React, { useContext, useState } from "react";
import { auth } from "../config/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { Grid } from "@mui/material";
import { Store } from "../Store";
import { Link, useNavigate } from "react-router-dom";
import classes from "../Assets/Styles/Signin.module.css";
import { ToastContainer, toast } from "react-toastify";

const SignupScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const signUp = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
        toast.success("Your Account has been created successfully");
        ctxDispatch({ type: "USER_SIGNIN", payload: { email, password } });
        localStorage.setItem("userInfo", JSON.stringify({ email, password }));
        navigate("/");
      })
      .catch((error) => toast.error("Wrong Inputs"));
  };

  return (
    <Grid item container direction="column" alignItems="center" marginTop={20}>
      <ToastContainer position="top-center" theme="dark" color="yellow" />
      <Grid
        item
        container
        justifyContent="center"
        justifyItems="center"
        width="40%"
        className={classes["HomeCardDetails"]}
      >
        <form onSubmit={signUp}>
          <Grid item container direction="column">
            <Grid item color="white">
              <h1>Create your account</h1>
            </Grid>
            <Grid item fontSize={23} marginBottom={1.5}>
              <div style={{ color: "white" }}>Email</div>
            </Grid>
            <input
              type="text"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes["SampleInputForm"]}
            ></input>
            <Grid item fontSize={23} marginBottom={1.5} marginTop={1.5}>
              <div style={{ color: "white" }}>Password</div>
            </Grid>
            <input
              type="password"
              placeholder="Enter your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes["SampleInputForm"]}
            ></input>
            <Grid item marginLeft={5} marginTop={1.5}>
              <Link to="/signin">
                <div>You already signed up? Sign in</div>
              </Link>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            marginTop={3.5}
            marginBottom={4}
          >
            <button type="submit" className={classes["SampleActiveButton"]}>
              Sign Up
            </button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default SignupScreen;
